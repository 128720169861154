import { KdramaChatProjectMeta } from '@pixels/universal/model/apps/project-meta/kdrama-chat-project-meta';
import { EnvironmentsConfig } from '@pixels/universal/model/firebase/environment.model';
import { Phase } from '@pixels/universal/model/phase-model';
import { DEV_PROXY_URL } from '@pixels/universal/model/proxy/proxy.model';

export const environment: EnvironmentsConfig = {
  production: true,
  apiUrl: KdramaChatProjectMeta.dev?.apiUrl ?? '',
  phase: Phase.dev,
  firebase: KdramaChatProjectMeta.dev?.firebaseConfig ?? {},
  talkPlusAppId: KdramaChatProjectMeta.dev?.talkPlusAppId ?? '',
  vapidKey: KdramaChatProjectMeta.dev?.webPushKey ?? '',
  useEmulators: false,
  proxyUrl: DEV_PROXY_URL
};
